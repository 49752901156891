import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/garrt_blog_header.png");
const section_1 = require("../../../assets/img/blogs/garrt_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/garrt_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/garrt_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/garrt_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/garrt_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/garrt_blog_img_6.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");

const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Reduce Resolution Time with Generative AI"
        description="Simplify incident handling & shorten resolution times with GenAI."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt garrt_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog color-white">
                    How Generative AI Can Help Reduce Resolution Time
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog color-white">
                    How Generative AI Can <br />
                    Help Reduce Resolution Time
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is Generative AI in service desks?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. What is the Mean Time To Resolution in the service desk?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Why is the mean time to resolution essential for your
                  service desk?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Why do service desks experience higher MTTR?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. How can we use Generative AI to reduce resolution time in
                  Service Desks?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Benefits of Generative AI-optimized MTTR for your business
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. Elevate your MTTR game with Workative.
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section8"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section8"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section8")}
                >
                  8. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Out of a variety of service desk performance metrics, MTTR or
                Mean Time to Resolution or Mean Time to Repair is critical.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Service desks generally use this metric to ensure service
                efficiency and employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Regardless of your IT responsibilities, the service desk must
                build effective responses and resolutions to mitigate downtime
                and ensure productivity.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                MTTR provides significant metrics that tell the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  exact time taken to resolve an issue or employee support
                  request.
                </span>{" "}
                Hence, service desks always aim to reduce MTTR and boost
                efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unfortunately, reducing MTTR is the biggest challenge for
                service desk managers today. According to the Observability
                Pulse Survey by Logz.io, IT and DevOps leaders claimed that
                their{" "}
                <a href="https://logz.io/observability-pulse-2024/">
                  MTTR during production incidents was over an hour.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Companies using AI improve MTTR. When they upgrade their AI
                tools to{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Generative AI,
                </span>{" "}
                they can{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  dramatically reduce MTTR
                </span>{" "}
                and drive employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here’s how Generative AI can help reduce resolution time or MTTR
                for your service desks.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is Generative AI in service desks?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI is a subset of AI/ML technologies that uses deep
                learning and neural networks to demonstrate excellent human
                language capabilities, synthesize complex or simple queries, and{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  transform service desk performance.{" "}
                </span>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With inherent natural language processing (NLP) and natural
                language generation (NLG) capabilities, Generative AI can refer
                to training data patterns to create new content.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Service desks seamlessly use Generative AI to create new
                responses and automate communications,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  effectively mitigating risks and lowering MTTR.{" "}
                </span>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is the Mean Time To Resolution in the service desk?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Mean Time to Resolution
                </span>{" "}
                (MTTR) is a metric that helps measure the time from receiving a
                ticket to resolving it. It encompasses both wait times and
                resolution times.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                MTTR involves four critical steps to determine the average time
                to resolve a service request for employee support. Generally, it
                depends on the effectiveness of your incident response systems,
                which include,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  1. Report — time taken to report a particular issue
                </li>
                <li className="font-section-normal-text-testimonials">
                  2. Response — time taken to respond to the issue
                </li>
                <li className="font-section-normal-text-testimonials">
                  3. Resolve — time taken to resolve an issue
                </li>
                <li className="font-section-normal-text-testimonials">
                  4. Recovery — time taken to recover from the incident
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Reducing MTTR means resolving issues and restoring operations or
                returning to work faster.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With all this said,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-service-desk">
                  Generative AI
                </a>{" "}
                can help address issues proactively to not just fix them for the
                time being but ensure they do not happen again. It also helps
                observe other key things, such as root cause analysis, incident
                communications, and post-mortem analysis, to ensure tickets are
                declared resolved correctly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Lowering MTTR is essential and stays on top of service desk
                managers’ heads.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is the mean time to resolution essential for your service
                desk?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generally, it is essential to observe how long it takes to
                resolve an issue from when a ticket is reported until it is
                resolved and service is restored. Service desk managers need to
                know whether this duration is justifiable.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Higher MTTR has negative impacts on employee experience,
                productivity, and efficiency.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small pl-4 mb-1">
                <li className="font-section-sub-header-small">
                  User experience
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Long MTTR means delayed resolutions. Employees can have hampered
                productivity, longer wait times, and become frustrated.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small pl-4 mb-1">
                <li className="font-section-sub-header-small">
                  Operational efficiency
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Higher MTTR can ruin work consistency. Until a disruption is
                fixed to bring a system back to work, employees tend to lose a
                significant number of productive hours.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Service desk managers aim to reduce MTTR, but existing processes
                challenge them.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why do service desks experience higher MTTR?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                A lot of technological innovations has taken place in AI.
                However, companies should pay more attention to these
                developments and adopt them. Unfortunately, service desks are
                not leveraging advanced AI and are struggling to tackle higher
                MTTR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Some key causes of higher MTTR include,
              </p>
              <h3 className="font-section-sub-header-small">IT complexity </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                IT has become quite complex. The lack of advanced tools makes it
                challenging for service desks to identify issues and offer
                resolutions, which increases MTTR.
              </p>
              <h3 className="font-section-sub-header-small">
                Limited automation{" "}
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Manual processes are slow and error-prone. Service desks use
                automation, but there is still some friction. Detecting,
                diagnosing, and suggesting actions is difficult.
              </p>
              <h3 className="font-section-sub-header-small">
                Lack of knowledge base optimization{" "}
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Interestingly, knowledge bases are usually only for agents.
                Employees need more opportunities to optimize their knowledge
                bases.
              </p>
              <h3 className="font-section-sub-header-small">
                Poor self-service{" "}
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Autonomous problem resolutions are limited to self-service.
                Though automation is applied to search information, it can
                create friction when employees seek help for multi-level
                workflows.
              </p>
              <h3 className="font-section-sub-header-small">
                Lack of visibility and monitoring
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Having worked with a legacy model for a long time, service desks
                struggle to gain visibility into organization-wide system
                performance. They need effective monitoring and visibility
                systems for real-time alerting.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI ensures a practical approach to overcoming the
                present challenges in service desks and improving performance to
                reduce MTTR.
              </p>
            </div>

            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How can we use Generative AI to reduce resolution time Service
                Desks
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Utilizing Generative AI to manage MTTR is essential to drive
                service desk success. Here are ways to do it.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Smart routing of tickets
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                For service desk agents, it is much easier to optimize MTTR or
                reduce resolution time for any incident by deciphering an
                incident message rapidly and accurately in real-time with GenAI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Identifying actual incidents and triaging them is a critical job
                for service desk agents to communicate in real time and improve
                incident response.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, this is a common scenario across all service desks,
                where they spend a lot of time exploring the real contexts of
                tickets about incidents.
              </p>
              <h4 className="font-section-normal-text-medium">
                This is where a service desk struggles, increasing MTTR.{" "}
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Though there are search engines like Google that help determine
                what a particular incident means in Jira or other ITSM tools,
                information overload can be information fatigue, offering no
                real help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using NLP and NLU technologies in large language models,
                Generative AI helps decipher a message sent by employees to the
                service desk.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Domain-specific data such as ticket history in Jira or other
                ITSM platforms, recommendations suggested by agents, or new
                mitigation actions, etc., can be useful data points to build a
                predictive Generative AI model and use across ticketing systems
                to learn across user behavior. All the data points a model is
                trained with can combine to help offer the right prediction
                about an incident.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Below is a flow of how incident predictions work:
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>Historical data fetched from the ticketing system</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>Predictive model built using GenAI</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>Further model improvement using current actions</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>4. </span>
                  <span>
                    Prediction delivered for systems impacted by an incident
                  </span>
                </li>
              </ul>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="GenAI model to automate incident triage"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                On top of it, there is a workflow engine embedded in a ticketing
                system that helps triage an incident without manual help and
                suggests an improved prediction to route the ticket to the right
                team to handle the issue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The predictive model’s responsibility does not end there. It
                continues to improve its learning capability to understand
                incident patterns, improve its prediction capability, and help
                the service desk automate incident triage and route incident
                tickets to the right team.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Root cause analysis and problem mitigation
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Usually, service desk agents try to navigate through history or
                user interactions to provide exemplary mitigation efforts for an
                incident.
              </p>
              <p className="font-section-normal-text-testimonials-medium line-height-18">
                The approach is less effective and impacts the MTTR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/digital-transformation-generative-ai">
                  {" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    A generative AI-embedded service desk
                  </span>{" "}
                  can be powerful in seeking a{" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    root cause analysis,
                  </span>{" "}
                  in which a model can define the exact cause of a downtime or
                  performance issue.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At the same time, integrated GenAI technology can offer a
                mitigation plan. As a result, reducing MTTR for service desk
                agents is a more time-saving opportunity, as they no longer need
                to look for similar incident types in the service desk history.
              </p>
              <BlogCta
                ContentCta="Reduce resolution time with Generative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Simultaneously, implementing an{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  automated workflow
                </span>{" "}
                is an efficient way to effectively escalate mitigation efforts
                and prevent downtime by implementing the proper fixes on the
                impacted applications or systems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if the CPU fails due to overutilization of disk
                space, a workflow may be automated to clean up the disk space
                and reduce MTTR without the need to wait for an agent to restore
                the problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI can also be effective in another scenario to help
                minimize MTTR by allowing a service desk agent to communicate in
                the chat interface of the observability tool, enter a prompt,
                and ask everything about what went wrong, how it went wrong, and
                what the proper fix for the impact is.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It can answer everything and offer a real-time fix for the
                problem.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="GenAI helps detect incidents and mitigate the impact"
              />
              <h3 className="font-section-sub-header-small-home">
                New agent to get remediation help
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge is always a significant resource for new agents
                handling an issue midway through a mitigation process when an
                experienced agent is off duty for a couple of hours or on a day
                off.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk may have SOPs, remediation documents, help
                guides, and other resources to resolve the problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These resources may be helpful but ineffective in mitigating an
                incident in minimal time and reducing MTTR. If the goal is to
                reduce MTTR and offer a rapid restoration solution, who has so
                much time to read through these resources?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Good on-call interaction is always efficient for making
                real-time decisions and ample tools and resources to mitigate
                the incident.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Say, a user has a jammed paper issue in his printer, and there
                are not enough recommendations in the knowledge base to get out
                of the downtime so that he can ask for an agent's help. The
                agent then uses an LLM-powered chat interface to surface the
                right mitigation effort and help reduce the downtime issue for
                that user.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Agent assist via a GenAI service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                A GenAI model integrated with IT issues-related resources can
                offer a suitable recommendation to a new agent when asked
                questions in a{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  question-and-answer playground.
                </span>{" "}
                Besides, a group of agents can also participate in incident
                communication and help surface the correct incident mitigation
                responses.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Stakeholder communications or status updates
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                To reduce MTTR, it is always significant to keep stakeholders
                updated so that they are aware of the mitigation efforts and
                their constraints, if any, so as to prepare them for future
                incidents and allocate appropriate resources.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                And that’s what it needs to craft a message to update
                stakeholders. It is not unusual for any enterprise to struggle
                to craft a message for updates post-incident resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In some instances, enterprises involve as many as two or three
                people to craft a standard message to send to stakeholders.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Now, imagine the time and resources it takes to create an email
                or any other form of incident communication.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Generative AI is a savior here. By entering{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  incident-related prompts,
                </span>{" "}
                one can adeptly draft, review, and create an email or
                post-resolution incident communications and rapidly build
                post-incident communications with stakeholders.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="content generation using a GenAI service desk"
              />
              <h3 className="font-section-sub-header-small-home">
                Postmortems of incidents using Generative AI
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A postmortem of incident resolution is critical to building
                resilience into operational efficiency, taking preventative
                measures for any system downtime, and reducing MTTR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A postmortem report involves working around thousands of data
                points 一 incident communications in Slack or MS Teams, incident
                logs, monitoring alerts, and just about everything from the
                beginning of the incident triage to the end of resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Just imagine having a team that can provide so much detail for a
                single incident and draft an error-proof postmortem report.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                It seems challenging and an open ground for information slipping
                through the cracks.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="GenAI powered postmortem report generation"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                However, having Generative AI to fetch every data point from the
                incident resolution journey makes the job easy while helping you
                automate the generation of an accurate postmortem draft for
                stakeholders.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Similarly, this automated postmortem report is a comprehensive
                guide for service desk agents to look into what went wrong, what
                the frequency of downtime for that particular application, what
                the areas of improvement, and above the cut, what can be done to
                prevent the same incident from recurring.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By drafting a postmortem report ahead of time, the service desk
                can help DevOps reduce MTTR and have complete peace of mind.
              </p>
              <h3 className="font-section-sub-header-small-home">
                AI copilot to reduce frequent incidents
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Other than using GenAI for the service desk to reduce MTTR,
                enterprises can choose to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ai-co-pilot-it-operations">
                  build AI Copilot
                </a>{" "}
                using Generative AI and leverage domain-specific data such as
                employee training materials, internet resources, knowledge
                bases, software documentation, etc. to train the model and embed
                it in the enterprise application suite.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What you gain from the Copilot is a real-time alert that keeps
                you updated on the upcoming incident related to that application
                and provides the right resolution steps ahead of time for
                mitigation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, your enterprise uses an Oracle database. If there
                is an attempt to infringe on the internal database, Copilot can
                flag the threat ahead of time and provide possible measures to
                prevent security breaches.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Active alerting to reduce downtime
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A Generative AI-powered AI copilot is your AI assistant to help
                you end-to-end with routine and everyday tasks. If your service
                desk creates a workflow for upcoming threats, your employees can
                easily handle them without raising a ticket for the service desk
                agents, which will help reduce MTTR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An alerting workflow works best if your employees are about to
                face password expiry. A timely alert can help avoid downtime and
                improve MTTR.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Benefits of Generative AI-optimized MTTR for your business
              </h2>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="MTTR gains through the GenAI service desk "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Reducing MTTR brings a lot of business gains for enterprise
                leaders. Here are what they include,
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Long-term incident resolution -{" "}
                </span>{" "}
                Yes, Generative AI offers an easy iteration for drafting
                comprehensive postmortem reports. This helps gain a data-driven
                understanding of future incidents and be incident-ready to
                mitigate them as per the MTTR strategy. Since the same type of
                incident is frequent, service desks can help IT teams take
                advantage of process efficiency and effectively reduce the
                tendency of future incidents.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  More free time for critical incidents -{" "}
                </span>{" "}
                GenAI automates incident triage and helps escalate incident
                tickets to the right team. It saves the service desk team ample
                time and helps DevOps get back to operations by resolving
                real-time incidents and allocating time to address more critical
                issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Low dependency on agents -{" "}
                </span>{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  GenAI and conversational AI are
                </span>{" "}
                the perfect combination to facilitate service desk
                communications via a self-service chat interface. Using NLP and
                NLU technologies, Generative AI can help surface the correct
                information to mitigate incidents via a self-service capability.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/">Workativ</a> provides the
                flexibility to build KnowledgeAI for the service desk chatbot
                and allows DevOps to resolve incidents at scale by engaging in
                self-service problem resolution or getting help from service
                desk agents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Continuous improvement of incident management -{" "}
                </span>{" "}
                GenAI has the unique ability to self-learn and build a
                predictive model on an ongoing basis, making it easy for the
                service desk to make real-time predictions of incidents,
                communicate effectively with the right team, and resolve the
                issues. As a result, it helps improve MTTR and enhances DevOps
                uptime and system performance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Bottom line cost savings -{" "}
                </span>
                GenAI does not need massive resources to monitor systems and
                ensure system performance continuously. Automated workflows can
                easily detect an anomaly in systems, alert the right team, and
                help reduce the impact of incidents. This is a considerable cost
                saving on the bottom line for average-scale enterprises that own
                a comprehensive IT infrastructure.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Elevate your MTTR game with Workativ
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                MTTR is a metric that service desks use to help DevOps keep
                their systems performant and running constantly without facing
                long-term incidents.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Mean Time to Resolution is always a challenging metric.
                Sometimes, mitigating a simple incident may be prolonged, or a
                critical incident may take less time than expected. Hence, the
                objective should be to allocate resources to manage time and
                have a strategy that helps optimize time and prevent recurrence.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 ">
                This is where Generative AI helps build healthy service desks by
                effectively and efficiently aiding service desks in reducing
                MTTR.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                By leveraging the power of Generative AI,{" "}
                <a href="https://workativ.com/">
                  Workativ can help service desks
                </a>{" "}
                use its LLM-powered chatbot and apply self-service capability
                via Slack or MS Teams. This is a great, convenient tool for
                communicating incidents to service desk agents or implying
                self-service capability to solve low-priority ticket issues.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                For example, Workativ offers{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  KnowledgeAI
                </a>{" "}
                technology with the power of hybrid NLU that helps enterprises
                build their custom knowledge bases with IT operations across
                industries and domain-specific IT issues. This competency allows
                users to leverage problem-solving techniques to autonomously
                solve common IT issues effectively and gain real-time MTTR
                benefits.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                There is more to Workativ that can help you enhance MTTR and
                build an elevated user experience for your employees.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                Want to learn more about Workativ conversational AI solutions
                and their MTTR reduction capability?
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/contact-us">
                  Get in touch with us
                </a>{" "}
                or{" "}
                <a href="/conversational-ai-platform/demo">
                  schedule a personalized demo
                </a>{" "}
                today.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h2 class="font-section-sub-header-small color-black">
                How does Generative AI contribute to reducing MTTR (Mean Time to
                Resolution) in service desk operations, and what are its key
                advantages over traditional methods?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI, particularly when integrated into service desk
                operations, offers several advantages in reducing MTTR. Firstly,
                it leverages natural language processing (NLP) and natural
                language understanding (NLU) capabilities to quickly decipher
                incident messages, enabling service desk agents to understand
                and respond to issues more rapidly. By automating incident
                triage and routing, Generative AI streamlines the process of
                identifying and prioritizing incidents, thus accelerating
                resolution times. Additionally, Generative AI can assist in root
                cause analysis by identifying the underlying causes of incidents
                and providing mitigation plans, further expediting the
                resolution process. Overall, its ability to automate tasks,
                analyze data, and provide real-time assistance significantly
                enhances the efficiency of service desk operations, ultimately
                leading to reduced MTTR compared to traditional methods.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                Are there any potential limitations or challenges associated
                with implementing Generative AI for MTTR optimization in service
                desk management, and how can these be addressed effectively?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                While Generative AI offers numerous benefits, its implementation
                may face certain challenges. One potential limitation is the
                need for robust data sets to train the AI model effectively.
                Gathering and preprocessing this data can be time-consuming and
                resource-intensive. Moreover, ensuring the accuracy and
                reliability of the AI model requires ongoing monitoring and
                refinement. Additionally, Generative AI may encounter
                difficulties in handling complex or ambiguous queries, leading
                to potential errors in incident resolution. To address these
                challenges, organizations can invest in comprehensive data
                collection and preprocessing strategies, implement rigorous
                model validation and testing procedures, and provide ongoing
                training and support to service desk agents to supplement AI
                capabilities.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                What specific metrics or benchmarks can be used to measure the
                effectiveness of Generative AI in reducing MTTR within service
                desk operations, and how can organizations ensure continuous
                improvement in this area over time?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Measuring the effectiveness of Generative AI in reducing MTTR
                requires the use of various metrics and benchmarks. Key
                performance indicators (KPIs) such as average resolution time,
                incident response time, and customer satisfaction scores can
                provide valuable insights into the impact of Generative AI on
                service desk operations. Additionally, organizations can track
                metrics related to incident volume, severity, and recurrence to
                assess the overall efficiency and effectiveness of AI-driven
                incident management processes. To ensure continuous improvement
                in this area, organizations should regularly analyze performance
                data, identify areas for optimization, and implement targeted
                strategies to enhance AI capabilities and streamline service
                desk workflows. Moreover, fostering a culture of innovation and
                collaboration can encourage ongoing experimentation and
                refinement of Generative AI solutions, driving sustained
                improvements in MTTR over time.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is Generative AI in service desks?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. What is the Mean Time To Resolution in the service desk?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Why is the mean time to resolution essential for your
                    service desk?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Why do service desks experience higher MTTR?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. How can we use Generative AI to reduce resolution time in
                    Service Desks?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Benefits of Generative AI-optimized MTTR for your
                    business
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. Elevate your MTTR game with Workative.
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section8"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section8"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section8")}
                  >
                    8. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Out of a variety of service desk performance metrics, MTTR or
                Mean Time to Resolution or Mean Time to Repair is critical.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Service desks generally use this metric to ensure service
                efficiency and employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Regardless of your IT responsibilities, the service desk must
                build effective responses and resolutions to mitigate downtime
                and ensure productivity.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                MTTR provides significant metrics that tell the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  exact time taken to resolve an issue or employee support
                  request.
                </span>{" "}
                Hence, service desks always aim to reduce MTTR and boost
                efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unfortunately, reducing MTTR is the biggest challenge for
                service desk managers today. According to the Observability
                Pulse Survey by Logz.io, IT and DevOps leaders claimed that
                their{" "}
                <a href="https://logz.io/observability-pulse-2024/">
                  MTTR during production incidents was over an hour.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Companies using AI improve MTTR. When they upgrade their AI
                tools to{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Generative AI,
                </span>{" "}
                they can{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  dramatically reduce MTTR
                </span>{" "}
                and drive employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here’s how Generative AI can help reduce resolution time or MTTR
                for your service desks.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is Generative AI in service desks?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI is a subset of AI/ML technologies that uses deep
                learning and neural networks to demonstrate excellent human
                language capabilities, synthesize complex or simple queries, and{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  transform service desk performance.{" "}
                </span>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With inherent natural language processing (NLP) and natural
                language generation (NLG) capabilities, Generative AI can refer
                to training data patterns to create new content.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Service desks seamlessly use Generative AI to create new
                responses and automate communications,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  effectively mitigating risks and lowering MTTR.{" "}
                </span>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is the Mean Time To Resolution in the service desk?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Mean Time to Resolution
                </span>{" "}
                (MTTR) is a metric that helps measure the time from receiving a
                ticket to resolving it. It encompasses both wait times and
                resolution times.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                MTTR involves four critical steps to determine the average time
                to resolve a service request for employee support. Generally, it
                depends on the effectiveness of your incident response systems,
                which include,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  1. Report — time taken to report a particular issue
                </li>
                <li className="font-section-normal-text-testimonials">
                  2. Response — time taken to respond to the issue
                </li>
                <li className="font-section-normal-text-testimonials">
                  3. Resolve — time taken to resolve an issue
                </li>
                <li className="font-section-normal-text-testimonials">
                  4. Recovery — time taken to recover from the incident
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Reducing MTTR means resolving issues and restoring operations or
                returning to work faster.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With all this said,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-service-desk">
                  Generative AI
                </a>{" "}
                can help address issues proactively to not just fix them for the
                time being but ensure they do not happen again. It also helps
                observe other key things, such as root cause analysis, incident
                communications, and post-mortem analysis, to ensure tickets are
                declared resolved correctly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Lowering MTTR is essential and stays on top of service desk
                managers’ heads.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is the mean time to resolution essential for your service
                desk?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generally, it is essential to observe how long it takes to
                resolve an issue from when a ticket is reported until it is
                resolved and service is restored. Service desk managers need to
                know whether this duration is justifiable.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Higher MTTR has negative impacts on employee experience,
                productivity, and efficiency.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small pl-4 mb-1">
                <li className="font-section-sub-header-small">
                  User experience
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Long MTTR means delayed resolutions. Employees can have hampered
                productivity, longer wait times, and become frustrated.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small pl-4 mb-1">
                <li className="font-section-sub-header-small">
                  Operational efficiency
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Higher MTTR can ruin work consistency. Until a disruption is
                fixed to bring a system back to work, employees tend to lose a
                significant number of productive hours.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Service desk managers aim to reduce MTTR, but existing processes
                challenge them.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why do service desks experience higher MTTR?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                A lot of technological innovations has taken place in AI.
                However, companies should pay more attention to these
                developments and adopt them. Unfortunately, service desks are
                not leveraging advanced AI and are struggling to tackle higher
                MTTR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Some key causes of higher MTTR include,
              </p>
              <h3 className="font-section-sub-header-small">IT complexity </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                IT has become quite complex. The lack of advanced tools makes it
                challenging for service desks to identify issues and offer
                resolutions, which increases MTTR.
              </p>
              <h3 className="font-section-sub-header-small">
                Limited automation{" "}
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Manual processes are slow and error-prone. Service desks use
                automation, but there is still some friction. Detecting,
                diagnosing, and suggesting actions is difficult.
              </p>
              <h3 className="font-section-sub-header-small">
                Lack of knowledge base optimization{" "}
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Interestingly, knowledge bases are usually only for agents.
                Employees need more opportunities to optimize their knowledge
                bases.
              </p>
              <h3 className="font-section-sub-header-small">
                Poor self-service{" "}
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Autonomous problem resolutions are limited to self-service.
                Though automation is applied to search information, it can
                create friction when employees seek help for multi-level
                workflows.
              </p>
              <h3 className="font-section-sub-header-small">
                Lack of visibility and monitoring
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Having worked with a legacy model for a long time, service desks
                struggle to gain visibility into organization-wide system
                performance. They need effective monitoring and visibility
                systems for real-time alerting.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI ensures a practical approach to overcoming the
                present challenges in service desks and improving performance to
                reduce MTTR.
              </p>
            </div>

            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How can we use Generative AI to reduce resolution time Service
                Desks
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Utilizing Generative AI to manage MTTR is essential to drive
                service desk success. Here are ways to do it.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Smart routing of tickets
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                For service desk agents, it is much easier to optimize MTTR or
                reduce resolution time for any incident by deciphering an
                incident message rapidly and accurately in real-time with GenAI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Identifying actual incidents and triaging them is a critical job
                for service desk agents to communicate in real time and improve
                incident response.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, this is a common scenario across all service desks,
                where they spend a lot of time exploring the real contexts of
                tickets about incidents.
              </p>
              <h4 className="font-section-normal-text-medium">
                This is where a service desk struggles, increasing MTTR.{" "}
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Though there are search engines like Google that help determine
                what a particular incident means in Jira or other ITSM tools,
                information overload can be information fatigue, offering no
                real help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using NLP and NLU technologies in large language models,
                Generative AI helps decipher a message sent by employees to the
                service desk.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Domain-specific data such as ticket history in Jira or other
                ITSM platforms, recommendations suggested by agents, or new
                mitigation actions, etc., can be useful data points to build a
                predictive Generative AI model and use across ticketing systems
                to learn across user behavior. All the data points a model is
                trained with can combine to help offer the right prediction
                about an incident.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Below is a flow of how incident predictions work:
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>Historical data fetched from the ticketing system</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>Predictive model built using GenAI</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>Further model improvement using current actions</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>4. </span>
                  <span>
                    Prediction delivered for systems impacted by an incident
                  </span>
                </li>
              </ul>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="GenAI model to automate incident triage"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                On top of it, there is a workflow engine embedded in a ticketing
                system that helps triage an incident without manual help and
                suggests an improved prediction to route the ticket to the right
                team to handle the issue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The predictive model’s responsibility does not end there. It
                continues to improve its learning capability to understand
                incident patterns, improve its prediction capability, and help
                the service desk automate incident triage and route incident
                tickets to the right team.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Root cause analysis and problem mitigation
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Usually, service desk agents try to navigate through history or
                user interactions to provide exemplary mitigation efforts for an
                incident.
              </p>
              <p className="font-section-normal-text-testimonials-medium line-height-18">
                The approach is less effective and impacts the MTTR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/digital-transformation-generative-ai">
                  {" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    A generative AI-embedded service desk
                  </span>{" "}
                  can be powerful in seeking a{" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    root cause analysis,
                  </span>{" "}
                  in which a model can define the exact cause of a downtime or
                  performance issue.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At the same time, integrated GenAI technology can offer a
                mitigation plan. As a result, reducing MTTR for service desk
                agents is a more time-saving opportunity, as they no longer need
                to look for similar incident types in the service desk history.
              </p>
              <BlogCta
                ContentCta="Reduce resolution time with Generative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Simultaneously, implementing an{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  automated workflow
                </span>{" "}
                is an efficient way to effectively escalate mitigation efforts
                and prevent downtime by implementing the proper fixes on the
                impacted applications or systems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if the CPU fails due to overutilization of disk
                space, a workflow may be automated to clean up the disk space
                and reduce MTTR without the need to wait for an agent to restore
                the problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI can also be effective in another scenario to help
                minimize MTTR by allowing a service desk agent to communicate in
                the chat interface of the observability tool, enter a prompt,
                and ask everything about what went wrong, how it went wrong, and
                what the proper fix for the impact is.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It can answer everything and offer a real-time fix for the
                problem.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="GenAI helps detect incidents and mitigate the impact"
              />
              <h3 className="font-section-sub-header-small-home">
                New agent to get remediation help
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge is always a significant resource for new agents
                handling an issue midway through a mitigation process when an
                experienced agent is off duty for a couple of hours or on a day
                off.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk may have SOPs, remediation documents, help
                guides, and other resources to resolve the problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These resources may be helpful but ineffective in mitigating an
                incident in minimal time and reducing MTTR. If the goal is to
                reduce MTTR and offer a rapid restoration solution, who has so
                much time to read through these resources?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Good on-call interaction is always efficient for making
                real-time decisions and ample tools and resources to mitigate
                the incident.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Say, a user has a jammed paper issue in his printer, and there
                are not enough recommendations in the knowledge base to get out
                of the downtime so that he can ask for an agent's help. The
                agent then uses an LLM-powered chat interface to surface the
                right mitigation effort and help reduce the downtime issue for
                that user.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Agent assist via a GenAI service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                A GenAI model integrated with IT issues-related resources can
                offer a suitable recommendation to a new agent when asked
                questions in a{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  question-and-answer playground.
                </span>{" "}
                Besides, a group of agents can also participate in incident
                communication and help surface the correct incident mitigation
                responses.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Stakeholder communications or status updates
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                To reduce MTTR, it is always significant to keep stakeholders
                updated so that they are aware of the mitigation efforts and
                their constraints, if any, so as to prepare them for future
                incidents and allocate appropriate resources.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                And that’s what it needs to craft a message to update
                stakeholders. It is not unusual for any enterprise to struggle
                to craft a message for updates post-incident resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In some instances, enterprises involve as many as two or three
                people to craft a standard message to send to stakeholders.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Now, imagine the time and resources it takes to create an email
                or any other form of incident communication.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Generative AI is a savior here. By entering{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  incident-related prompts,
                </span>{" "}
                one can adeptly draft, review, and create an email or
                post-resolution incident communications and rapidly build
                post-incident communications with stakeholders.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="content generation using a GenAI service desk"
              />
              <h3 className="font-section-sub-header-small-home">
                Postmortems of incidents using Generative AI
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A postmortem of incident resolution is critical to building
                resilience into operational efficiency, taking preventative
                measures for any system downtime, and reducing MTTR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A postmortem report involves working around thousands of data
                points 一 incident communications in Slack or MS Teams, incident
                logs, monitoring alerts, and just about everything from the
                beginning of the incident triage to the end of resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Just imagine having a team that can provide so much detail for a
                single incident and draft an error-proof postmortem report.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                It seems challenging and an open ground for information slipping
                through the cracks.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="GenAI powered postmortem report generation"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                However, having Generative AI to fetch every data point from the
                incident resolution journey makes the job easy while helping you
                automate the generation of an accurate postmortem draft for
                stakeholders.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Similarly, this automated postmortem report is a comprehensive
                guide for service desk agents to look into what went wrong, what
                the frequency of downtime for that particular application, what
                the areas of improvement, and above the cut, what can be done to
                prevent the same incident from recurring.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By drafting a postmortem report ahead of time, the service desk
                can help DevOps reduce MTTR and have complete peace of mind.
              </p>
              <h3 className="font-section-sub-header-small-home">
                AI copilot to reduce frequent incidents
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Other than using GenAI for the service desk to reduce MTTR,
                enterprises can choose to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ai-co-pilot-it-operations">
                  build AI Copilot
                </a>{" "}
                using Generative AI and leverage domain-specific data such as
                employee training materials, internet resources, knowledge
                bases, software documentation, etc. to train the model and embed
                it in the enterprise application suite.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What you gain from the Copilot is a real-time alert that keeps
                you updated on the upcoming incident related to that application
                and provides the right resolution steps ahead of time for
                mitigation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, your enterprise uses an Oracle database. If there
                is an attempt to infringe on the internal database, Copilot can
                flag the threat ahead of time and provide possible measures to
                prevent security breaches.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Active alerting to reduce downtime
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A Generative AI-powered AI copilot is your AI assistant to help
                you end-to-end with routine and everyday tasks. If your service
                desk creates a workflow for upcoming threats, your employees can
                easily handle them without raising a ticket for the service desk
                agents, which will help reduce MTTR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An alerting workflow works best if your employees are about to
                face password expiry. A timely alert can help avoid downtime and
                improve MTTR.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Benefits of Generative AI-optimized MTTR for your business
              </h2>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="MTTR gains through the GenAI service desk "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Reducing MTTR brings a lot of business gains for enterprise
                leaders. Here are what they include,
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Long-term incident resolution -{" "}
                </span>{" "}
                Yes, Generative AI offers an easy iteration for drafting
                comprehensive postmortem reports. This helps gain a data-driven
                understanding of future incidents and be incident-ready to
                mitigate them as per the MTTR strategy. Since the same type of
                incident is frequent, service desks can help IT teams take
                advantage of process efficiency and effectively reduce the
                tendency of future incidents.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  More free time for critical incidents -{" "}
                </span>{" "}
                GenAI automates incident triage and helps escalate incident
                tickets to the right team. It saves the service desk team ample
                time and helps DevOps get back to operations by resolving
                real-time incidents and allocating time to address more critical
                issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Low dependency on agents -{" "}
                </span>{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  GenAI and conversational AI are
                </span>{" "}
                the perfect combination to facilitate service desk
                communications via a self-service chat interface. Using NLP and
                NLU technologies, Generative AI can help surface the correct
                information to mitigate incidents via a self-service capability.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/">Workativ</a> provides the
                flexibility to build KnowledgeAI for the service desk chatbot
                and allows DevOps to resolve incidents at scale by engaging in
                self-service problem resolution or getting help from service
                desk agents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Continuous improvement of incident management -{" "}
                </span>{" "}
                GenAI has the unique ability to self-learn and build a
                predictive model on an ongoing basis, making it easy for the
                service desk to make real-time predictions of incidents,
                communicate effectively with the right team, and resolve the
                issues. As a result, it helps improve MTTR and enhances DevOps
                uptime and system performance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Bottom line cost savings -{" "}
                </span>
                GenAI does not need massive resources to monitor systems and
                ensure system performance continuously. Automated workflows can
                easily detect an anomaly in systems, alert the right team, and
                help reduce the impact of incidents. This is a considerable cost
                saving on the bottom line for average-scale enterprises that own
                a comprehensive IT infrastructure.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Elevate your MTTR game with Workativ
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                MTTR is a metric that service desks use to help DevOps keep
                their systems performant and running constantly without facing
                long-term incidents.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Mean Time to Resolution is always a challenging metric.
                Sometimes, mitigating a simple incident may be prolonged, or a
                critical incident may take less time than expected. Hence, the
                objective should be to allocate resources to manage time and
                have a strategy that helps optimize time and prevent recurrence.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 ">
                This is where Generative AI helps build healthy service desks by
                effectively and efficiently aiding service desks in reducing
                MTTR.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                By leveraging the power of Generative AI,{" "}
                <a href="https://workativ.com/">
                  Workativ can help service desks
                </a>{" "}
                use its LLM-powered chatbot and apply self-service capability
                via Slack or MS Teams. This is a great, convenient tool for
                communicating incidents to service desk agents or implying
                self-service capability to solve low-priority ticket issues.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                For example, Workativ offers{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  KnowledgeAI
                </a>{" "}
                technology with the power of hybrid NLU that helps enterprises
                build their custom knowledge bases with IT operations across
                industries and domain-specific IT issues. This competency allows
                users to leverage problem-solving techniques to autonomously
                solve common IT issues effectively and gain real-time MTTR
                benefits.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                There is more to Workativ that can help you enhance MTTR and
                build an elevated user experience for your employees.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                Want to learn more about Workativ conversational AI solutions
                and their MTTR reduction capability?
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/contact-us">
                  Get in touch with us
                </a>{" "}
                or{" "}
                <a href="/conversational-ai-platform/demo">
                  schedule a personalized demo
                </a>{" "}
                today.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h2 class="font-section-sub-header-small color-black">
                How does Generative AI contribute to reducing MTTR (Mean Time to
                Resolution) in service desk operations, and what are its key
                advantages over traditional methods?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI, particularly when integrated into service desk
                operations, offers several advantages in reducing MTTR. Firstly,
                it leverages natural language processing (NLP) and natural
                language understanding (NLU) capabilities to quickly decipher
                incident messages, enabling service desk agents to understand
                and respond to issues more rapidly. By automating incident
                triage and routing, Generative AI streamlines the process of
                identifying and prioritizing incidents, thus accelerating
                resolution times. Additionally, Generative AI can assist in root
                cause analysis by identifying the underlying causes of incidents
                and providing mitigation plans, further expediting the
                resolution process. Overall, its ability to automate tasks,
                analyze data, and provide real-time assistance significantly
                enhances the efficiency of service desk operations, ultimately
                leading to reduced MTTR compared to traditional methods.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                Are there any potential limitations or challenges associated
                with implementing Generative AI for MTTR optimization in service
                desk management, and how can these be addressed effectively?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                While Generative AI offers numerous benefits, its implementation
                may face certain challenges. One potential limitation is the
                need for robust data sets to train the AI model effectively.
                Gathering and preprocessing this data can be time-consuming and
                resource-intensive. Moreover, ensuring the accuracy and
                reliability of the AI model requires ongoing monitoring and
                refinement. Additionally, Generative AI may encounter
                difficulties in handling complex or ambiguous queries, leading
                to potential errors in incident resolution. To address these
                challenges, organizations can invest in comprehensive data
                collection and preprocessing strategies, implement rigorous
                model validation and testing procedures, and provide ongoing
                training and support to service desk agents to supplement AI
                capabilities.
              </p>

              <h2 class="font-section-sub-header-small color-black">
                What specific metrics or benchmarks can be used to measure the
                effectiveness of Generative AI in reducing MTTR within service
                desk operations, and how can organizations ensure continuous
                improvement in this area over time?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Measuring the effectiveness of Generative AI in reducing MTTR
                requires the use of various metrics and benchmarks. Key
                performance indicators (KPIs) such as average resolution time,
                incident response time, and customer satisfaction scores can
                provide valuable insights into the impact of Generative AI on
                service desk operations. Additionally, organizations can track
                metrics related to incident volume, severity, and recurrence to
                assess the overall efficiency and effectiveness of AI-driven
                incident management processes. To ensure continuous improvement
                in this area, organizations should regularly analyze performance
                data, identify areas for optimization, and implement targeted
                strategies to enhance AI capabilities and streamline service
                desk workflows. Moreover, fostering a culture of innovation and
                collaboration can encourage ongoing experimentation and
                refinement of Generative AI solutions, driving sustained
                improvements in MTTR over time.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-service-management-guide"
              className="font-section-normal-text-testimonials"
            >
              A Complete Guide - 2023: HR Service Management
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-digital-transformation-guide-2023"
              className="font-section-normal-text-testimonials"
            >
              The ultimate guide - 2023: HR DIGITAL TRANSFORMATION
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-digital-transformation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Why do you need ITSM in your Digital Trasformation?
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
